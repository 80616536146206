.fixed-action-buttons {
  display: flex;
  position: fixed;
  right: 0px;
  top: 50%;
  transform: translateY(-50%) translateX(100%);
  flex-direction: column;
  background: #CF112E;
  align-content: center;
  align-self: center;
  align-items: center;
  padding: 6px 2px;
  z-index: 1;

  &.isActive {
    transition-duration: 0.3s;
    transform: translateY(-50%) translateX(0%);
  }

  img {
    height: 30px;
    width: auto;
    max-width: 35px;
    margin: 8px;
  }

  @media all and (max-width: 1200px) {
    img {
      height: 25px;
      max-width: 25px;
    }
  }

  @media all and (max-width: 800px) {
    padding: 0px;

    img {
      height: 20px;
      max-width: 20px;
      margin: 6px
    }
  }
}