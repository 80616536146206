.offer-item {
  margin: 32px;
  background: #C4C4C4;
  text-align: center;
  padding: 64px 16px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition-duration: 0s;
  transition-delay: 0.25s;
  cursor: pointer;
  z-index: 1;

  @media all and (max-width: 1600px) {
    padding: 32px 16px;
    margin: 24px;
  }

  @media all and (max-width: 1200px) {
    padding: 16px 16px 32px 16px;
    margin: 16px;
  }

  &:after {
    transition-delay: 0s;
    transition-duration: 0.7s;
    left: -200%;
    top: 0px;
    content: '';
    position: absolute;
    display: block;
    width: 200%;
    height: 100%;
    background: #C96A79;
    z-index: 5;
  }

  img {
    z-index: 3;
    margin: auto;
    filter: invert(1);
    transition-delay: 0.3s;
    user-select: none;
    max-width: 60%;
    margin-bottom: 24px;
    max-height: 120px;
  }

  .offerItem__name {
    z-index: 3;
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: 0.05em;
    color: black;
    transition-delay: 0.3s;
    user-select: none;

    @media all and (max-width: 1600px) {
      font-size: 3.2rem;
    }

    @media all and (max-width: 1200px) {
      font-size: 2.8rem;
    }

    @media all and (max-width: 800px) {
      font-size: 2.4rem;
    }
  }

  .offerItem__more {
    z-index: 3;
    margin-top: 32px;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 1.25;
    color: white;
    transition-delay: 0.3s;
    opacity: 0;
    user-select: none;

    @media all and (max-width: 1600px) {
      margin-top: 16px;
    }
  }

  &:hover {
    background: #CF112E;

    &::after {
      left: 100%;
    }

    img {
      filter: invert(0);
    }

    .offerItem__name {
      color: white;
    }

    .offerItem__more {
      opacity: 1;
      padding-left: 15px;
      transition-duration: 0.5s;
      transition-delay: 0.4s;
    }
  }
}