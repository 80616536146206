.textColImgCol-block {
  width: 100%;
  display: flex;
  padding-top: 32px;
  padding-bottom: 64px;


  @media all and (max-width: 800px) {
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .colRight {
    width: 40%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    justify-content: center;
    display: flex;
    min-height: 480px;

    @media all and (max-width: 800px) {
      background-position: center;
      width: 100%;
      height: 340px;
      background-size: cover;
      min-height: auto;
    }

    @media all and (max-width: 400px) {
      height: 220px;
    }
  }

  .colLeft {
    padding: 128px 128px 128px 0px;
    width: 60%;
    font-size: 2.4rem;
    letter-spacing: 0.05em;
    line-height: 1.65;
    font-weight: 300;

    .smallText {
      font-size: 1.6rem;
    }

    @media all and (max-width: 1600px) {
      padding: 64px 64px 64px 0px;
      line-height: 1.5;
    }

    @media all and (max-width: 1200px) {
      font-size: 2.2rem;
      padding: 32px 32px 32px 0px;
      line-height: 1.4;
    }

    @media all and (max-width: 800px) {
      width: 100%;
      padding: 0px 0px 32px 0px;
      font-size: 2rem;
    }

    @media all and (max-width: 500px) {
      width: 100%;
      padding: 0px 0px 32px 0px;
      font-size: 1.8rem;
    }
  }

  &.isReverse {
    flex-direction: row-reverse;

    @media all and (max-width: 800px) {
      flex-direction: column;
    }

    .colLeft {
      padding: 128px 32px 128px 128px;

      @media all and (max-width: 1600px) {
        padding: 64px 32px 64px 64px;
      }

      @media all and (max-width: 1200px) {
        padding: 32px 32px 32px 32px;
      }

      @media all and (max-width: 800px) {
        width: 100%;
        padding: 0px 0px 32px 0px;
      }
    }
  }

  &.alignTop {
    .colRight {
      background-position: center 128px;

      @media all and (max-width: 1600px) {
        background-position: center 64px;
      }

      @media all and (max-width: 1200px) {
        background-position: center 32px;
      }
    }
  }

  .map {
    width: 100%;
    height: 460px;
    margin-top: auto;
    margin-bottom: auto;
    filter: grayscale(1);

    @media all and (max-width: 1600px) {
      height: 360px;
    }

    @media all and (max-width: 1200px) {
      height: 260px;
    }
  }
}