.ofirmieBeton-page {
  width: 100%;
  height: auto;
  padding-bottom: 32px;
  background: white;

  @media all and (max-width: 800px) {
    padding-bottom: 0px;
  }

  .collapsibleBlocksWrapper {
    padding-bottom: 64px;

    @media all and (max-width: 800px) {
      padding-bottom: 32px;
    }

    .imgBlocksWrapper {
      display: flex;
      padding-top: 64px;
      padding-bottom: 32px;
    }

    .imgBlocks {
      margin: auto;
      width: 100%;
      max-width: 900px;
    }
  }
}