.footer {
  .container {
    padding-top: 12px;
    padding-bottom: 24px;
    flex-direction: row;

    .leftCol {
      width: 100%;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: 0.05em;
      margin-top: auto;
      margin-bottom: auto;
      @media all and (max-width: 1200px) {
        font-size: 1.6rem;
      }
    }

    .rightCol {
      width: auto;
      margin-top: 4px;
      padding-right: 2px;

      a {
        height: 34px;
      }

      img {
        height: 34px;
        @media all and (max-width: 1200px) {
          height: 28px;
        }
      }
    }
  }
}