.main-header {
  padding: 128px 0px;
  padding-bottom: 96px;
  font-weight: 600;
  font-size: 5.4rem;
  line-height: 1.1;
  letter-spacing: 0.05em;
  text-align: center;
  position: relative;
  width: 100%;

  @media all and (max-width: 1200px) {
    font-size: 4.8rem;
    padding: 64px 0px;
  }

  @media all and (max-width: 800px) {
    font-size: 4rem;
    padding-bottom: 32px;
  }

  @media all and (max-width: 500px) {
    font-size: 3.2rem;
    // padding-bottom: 16px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 5px;
    width: 150px;
    background: #CF112E;
    margin-top: 16px;

    @media all and (max-width: 1200px) {
      width: 125px;
      margin-top: 14px;
      height: 4px;
    }

    @media all and (max-width: 800px) {
      width: 100px;
      height: 3px;
    }
  }

  &.left {
    text-align: left;

    &:after {
      left: 0px;
    }
  }

  &.center {
    text-align: center;

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.right {
    text-align: right;

    &:after {
      right: 0px;
    }
  }
}