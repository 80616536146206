.navbarBar {
  width: 100%;
  height: 0px;
  position: fixed;
  background: rgba(255, 255, 255, 0);
  transition-duration: 0.3s;
  z-index: 888;

}

.navbarBar.navbarbarActive {
  height: 96px;
  background: white;
}

.navbarLogo {
  height: 48px;
  position: fixed;
  z-index: 999;
  display: flex;
  z-index: 999;
  left: 64px;
  top: 64px;
  transition-duration: 0.3s;

  img {
    cursor: pointer;
    height: 46px;
  }

  @media all and (max-width: 1200px) {
    top: 32px;
    left: 32px;

    img {
      height: 40px;
    }
  }


}

.navbarLogo.navbarbarActive {
  top: 24px;
}

.menuBtn {
  mix-blend-mode: difference;
  position: fixed;
  z-index: 999;
  right: 64px;
  top: 64px;
  transition-duration: 0.3s;
  cursor: pointer;
  z-index: 904;

  @media all and (max-width: 1200px) {
    top: 32px;
    right: 32px;
  }

  span {

    &::after {
      transition-duration: 0.2s;
      filter: grayscale(100%);
      margin: 10px 6px;
      margin-right: 0px;
      content: '';
      width: 36px;
      height: 2px;
      background: rgba(200, 200, 200, 1);
      display: block;
    }

    @media all and (max-width: 1200px) {
      &::after {
        margin: 8px 6px;
        margin-right: 0px;
        width: 32px;
        height: 1.7px;
      }
    }
  }

  &.isActive {
    mix-blend-mode: normal;
    transform: rotate(27deg);

    span {
      &::after {

        background: #fff !important;
        transform: rotate(30deg);
        margin: 14px 6px;
      }

      @media all and (max-width: 1200px) {
        &::after {
          margin: 10px 6px;
        }
      }

      // &:nth-child(1) {
      //   transform: rotate(45deg);
      // }
    }
  }
}

.menuBtn.navbarbarActive {
  top: 24px;
}

.mobileSideMenuOverlay {
  width: 150vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  right: 0px;
  transform: translateX(150vw);
  z-index: 900;
  transition-duration: 0.3s;
  backdrop-filter: blur(25px);

  &.isActive {
    background: rgba(99, 99, 99, 0.3);
    transform: translateX(45vw);
  }
}

.mobileSideMenu {
  z-index: 903;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  height: 100vh;
  position: fixed;
  right: 0px;
  transform: translateX(100%);
  padding: 64px;
  padding-top: 128px;
  color: white;
  text-align: right;
  transition-duration: 0.3s;
  overflow-y: auto;

  @media all and (max-width: 1200px) {
    padding: 32px;
    padding-top: 96px;
  }

  @media all and (max-width: 800px) {
    padding-top: 64px;
  }

  &.isActive {
    transform: translateX(0%);
  }

  .linkLarge {
    margin-top: 32px;
    font-size: 3.2rem;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
  }

  .linkSmall {
    margin-top: 16px;
    font-size: 2rem;
    cursor: pointer;
    user-select: none;

    @media all and (max-width: 1200px) {
      margin-top: 16px;
      font-size: 1.8rem;
    }
  }
}