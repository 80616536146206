.action-contact-section {
  width: 100%;
  height: auto;
  display: flex;
  padding-top: 96px;
  flex-direction: column;
  z-index: 0;
  background: white;

  .actionContactBelt {
    width: 100%;
    height: auto;
    background: #CF112E;
    padding-top: 8px;

    .topLinkWrapper {
      color: white;
      display: block;
      padding-top: 64px;
      padding-bottom: 64px;
      margin-left: 64px;
      cursor: pointer;
      font-weight: 600;
      font-size: 5.4rem;
      line-height: 1.25;
      letter-spacing: 0.05em;
      text-align: center;
      transition-duration: 0.2s;
      user-select: none;

      @media all and (max-width: 1600px) {
        font-size: 4.4rem;
        padding-top: 48px;
        padding-bottom: 48px;
        margin-left: 48px;
      }

      @media all and (max-width: 1200px) {
        font-size: 3.2rem;
        padding-top: 32px;
        padding-bottom: 32px;
        margin-left: 32px;
      }

      @media all and (max-width: 800px) {
        font-size: 2.4rem;
        padding-top: 32px;
        padding-bottom: 32px;
        margin-left: 16px;
      }

      @media all and (max-width: 600px) {
        font-size: 1.6rem;
      }

      span {
        transition-duration: 0.2s;
        padding-left: 32px;

        @media all and (max-width: 800px) {
          padding-left: 16px;
          font-size: 3.4rem;
        }

        @media all and (max-width: 600px) {
          font-size: 2.4rem;
        }

        @media all and (max-width: 300px) {
          display: none;
        }
      }

      &:hover {
        margin-left: 96px;

        @media all and (max-width: 1600px) {
          margin-left: 64px;
        }

        @media all and (max-width: 1200px) {
          margin-left: 48px;
        }

        @media all and (max-width: 800px) {
          margin-left: 32px;
        }

        span {
          padding-left: 64px;

          @media all and (max-width: 1600px) {
            padding-left: 48px;
          }

          @media all and (max-width: 1200px) {
            padding-left: 48px;
          }

          @media all and (max-width: 800px) {
            padding-left: 32px;
          }
        }
      }

      &:active {
        margin-left: 196px;

        @media all and (max-width: 1600px) {
          margin-left: 64px;
        }

        @media all and (max-width: 1200px) {
          margin-left: 48px;
        }

        @media all and (max-width: 800px) {
          margin-left: 32px;
        }

        span {
          padding-left: 164px;

          @media all and (max-width: 1600px) {
            padding-left: 48px;
          }

          @media all and (max-width: 1200px) {
            padding-left: 48px;
          }

          @media all and (max-width: 800px) {
            padding-left: 32px;
          }
        }
      }
    }
  }
}