.prefooter {
  background: white;

  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 80px;
    padding-bottom: 80px;

    @media all and (max-width: 1620px) {
      padding-bottom: 60px;
    }

    @media all and (max-width: 1200px) {
      padding-bottom: 40px;
      padding-top: 60px;
    }

    @media all and (max-width: 800px) {
      padding-bottom: 20px;
      padding-top: 40px;
    }

    .column350 {
      width: auto;
      padding-right: 250px;
      max-width: 550px;
      height: auto;

      @media all and (max-width: 1620px) {
        padding-right: 150px;
      }

      @media all and (max-width: 1100px) {
        padding-right: 100px;
      }

      @media all and (max-width: 800px) {
        width: 100%;
        padding-right: 0px;
      }

      &:nth-child(2) {
        @media all and (max-width: 800px) {
          padding-top: 32px;
        }
      }

      &:last-of-type {
        padding-right: 0px;

        @media all and (max-width: 1000px) {
          width: 100%;
          padding-top: 32px;
        }
      }
      .topLinkWrapper{
        font-weight: 400;
      }
      p {
        color: #3D3D3D;
        font-weight: 500;
        letter-spacing: 0.05em;
        line-height: 2;
        font-size: 1.6rem;
        span {
          color: #CF112E;
        }
      }
    }

    .prefooterNavTitle {
      color: #CF112E;
      font-weight: 500;
      font-size: 3.2rem;
      margin-bottom: 20px;
      letter-spacing: 0.05em;

      @media all and (max-width: 1200px) {
        font-size: 2.8rem;
        margin-bottom: 10px;
      }

      @media all and (max-width: 800px) {
        font-size: 2.4rem;
      }
    }

    .topLinkWrapper {
      font-weight: 300;
      font-size: 2.4rem;
      margin-bottom: 10px;
      cursor: pointer;

      @media all and (max-width: 1200px) {
        font-size: 2rem;
        margin-bottom: 6px;
      }
    }
  }
}