.redhero-section {
  height: 600px;
  width: 100%;
  align-items: center;
  display: flex;
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  background: #CF112E;

  @media all and (max-width: 1600px) {
    height: 500px;
  }

  @media all and (max-width: 1200px) {
    height: 400px;
  }

  @media all and (max-width: 600px) {
    height: 500px;
  }


  @keyframes toRightAnim {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0%);

    }
  }

  @keyframes toLeftAnim {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0%);
    }
  }

  .container {
    padding: 100px 64px;
    flex-direction: row;
    height: 100%;
    padding-bottom: 0px;

    @media all and (max-width: 600px) {
      flex-direction: column;
    }

    @media all and (max-width: 1200px) {
      height: 400px;
      padding: 100px 32px;
      padding-bottom: 0px;
    }

    .contentCol {
      width: 67%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translateX(0%);
      animation: toRightAnim 0.7s normal forwards;

      @media all and (max-width: 1600px) {
        width: 70%;
      }

      @media all and (max-width: 1200px) {
        width: 50%;
      }

      @media all and (max-width: 600px) {
        width: 100%;
      }

      .heroPath {
        color: white;
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.3;
        letter-spacing: 0.05em;
        padding-left: 5px;
        cursor: pointer;

        @media all and (max-width: 1600px) {
          font-size: 1.8rem;
        }

        @media all and (max-width: 1200px) {
          padding-top: 32px;
        }
      }

      .heroTitle {
        color: white;
        font-weight: 600;
        font-size: 6.4rem;
        line-height: 1.25;
        letter-spacing: 0.05em;
        margin-bottom: 64px;

        @media all and (max-width: 1600px) {
          font-size: 5.6rem;
        }

        @media all and (max-width: 1200px) {
          font-size: 4.4rem;
        }

        @media all and (max-width: 800px) {
          font-size: 4rem;
        }
      }
    }

    .iconCol {
      height: 100%;
      width: 33%;
      display: flex;
      transform: translateX(32px);

      @media all and (max-width: 1600px) {
        width: 30%;
      }

      @media all and (max-width: 1200px) {
        width: 50%;
      }

      @media all and (max-width: 800px) {
        transform: translateX(32px);
      }

      @media all and (max-width: 600px) {
        width: 100%;
        transform: translateX(0px);
      }

      img {
        transform: translateX(0%);
        animation: toLeftAnim 0.7s normal forwards ease-in-out;
        margin-top: auto;
        width: 100%;
        height: auto;
        margin-left: auto;

        @media all and (max-width: 1200px) {
          max-width: 360px;
        }

        @media all and (max-width: 600px) {
          width: 70%;
          max-width: 300px;
          margin-right: auto;
        }
      }
    }
  }
}