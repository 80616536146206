.slide-overlay {
  width: 200vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -200vw;
  transition-duration: 0.8s;

  &.slide {
    background: #A3A3A3; //C96A79;//C4C4C4;
    z-index: 9998;
  }

  &.slideSecond {
    background: #CF112E;
    z-index: 9999;
  }

  &.slideAnimation {
    left: 0vw;
  }
}