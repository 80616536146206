html{
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
  color: black;
  overflow: auto;
  scroll-behavior: smooth;
  background: white;
}

@media all and (max-width: 1200px) {
  html{
    font-size: 9px;
  }
}

@media all and (max-width: 600px) {
  html{
    font-size: 8px;
  }
}

body {
  position: relative;
  overflow: hidden;
}

*,
:before,
:after {
  box-sizing: border-box;
  margin: 0px;
}