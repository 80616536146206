.home-page {
  width: 100%;

  .heroSection {
    height: 100vh;
    width: 100%;
    display: flex;

    @media all and (max-width: 1700px) {
      height: 80vh;
    }

    @media all and (max-width: 1200px) {
      height: 60vh;
    }

    @media all and (max-width: 900px) {
      height: 50vh;
      min-height: 375px;
    }

    @media all and (max-width: 700px) {
      height: 400px;
    }

    @media all and (max-width: 500px) {
      height: 320px;
    }

    .leftHero,
    .rightHero {
      align-items: center;
      width: 50%;
      height: 100%;
      display: flex;
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;

    }

    .subLogo {
      height: 200px;
      width: 200px;
      box-shadow: 6px 0px 20px rgba(0, 0, 0, 0.25);
      transition-duration: 0.2s;
      cursor: pointer;

      @media all and (max-width: 1200px) {
        height: 170px;
        width: 170px;
      }

      @media all and (max-width: 900px) {
        height: 150px;
        width: 150px;
      }

      @media all and (max-width: 700px) {
        margin-top: 100px;
        height: 120px;
        width: 120px;
      }

      @media all and (max-width: 500px) {
        margin-top: 70px;
        height: 100px;
        width: 100px;
      }

      &:hover {
        transform: scale(1.1);
        box-shadow: 6px 10px 40px rgba(0, 0, 0, 0.4);
      }
    }

    .leftHero {
      .topLinkWrapper {
        margin-left: auto;
      }
    }
  }
}