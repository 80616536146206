.full-map-section {
  width: 100%;
  z-index: 0;

  .map {
    z-index: -1;
    filter: saturate(0);
    width: 100%;
    height: 700px;
    @media all and (max-width: 1600px) {
      height: 600px;
    }
    @media all and (max-width: 1200px) {
      height: 500px;
    }
    @media all and (max-width: 800px) {
      height: 400px;
    }
  }
}