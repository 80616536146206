.hero-section {
  height: 100vh;
  width: 100%;
  align-items: center;
  display: flex;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;

  @media all and (max-width: 1700px) {
    height: 80vh;
  }

  @media all and (max-width: 1200px) {
    height: 60vh;
  }

  @media all and (max-width: 900px) {
    height: 50vh;
    min-height: 375px;
  }

  @media all and (max-width: 700px) {
    height: 400px;
  }

  @media all and (max-width: 500px) {
    height: 320px;
  }

  img {
    height: 200px;
    width: 200px;
    box-shadow: 6px 0px 20px rgba(0, 0, 0, 0.25);
    transition-duration: 0.2s;

    &:hover {
      transform: scale(1.1);
      box-shadow: 6px 10px 40px rgba(0, 0, 0, 0.4);
    }

    @media all and (max-width: 1200px) {
      height: 170px;
      width: 170px;
    }

    @media all and (max-width: 900px) {
      height: 150px;
      width: 150px;
    }

    @media all and (max-width: 700px) {
      margin-top: 130px;
      height: 120px;
      width: 120px;
    }
  }
}