.collapsible-block {
  background: #CF112E;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;

  .collapsible-block__title {
    text-align: center;
    padding: 48px;
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: 0.05em;
    color: white;
    user-select: none;
    cursor: pointer;
    position: relative;

    @media all and (max-width: 1600px) {
      padding: 32px;
      font-size: 3.2rem;
    }

    @media all and (max-width: 1200px) {
      padding: 24px;
      font-size: 2.8rem;
    }

    @media all and (max-width: 800px) {
      padding: 16px 64px 16px 16px;
      text-align: left;
      font-size: 2.4rem;
    }

    @media all and (max-width: 500px) {
      font-size: 2rem;
    }

    .imgIcon {
      position: absolute;
      right: 48px;
      top: 50%;
      width: 32px;
      transform: translateY(-50%);
      transition-duration: 0.3s;

      @media all and (max-width: 1600px) {
        width: 28px;
        right: 40px;
      }

      @media all and (max-width: 1200px) {
        width: 24px;
        right: 32px;
      }

      @media all and (max-width: 800px) {
        width: 20px;
        right: 24px;
      }
    }

    .imgIcon.isOpen {
      transform: translateY(-50%) scaleY(-1);
    }
  }

  .collapsible-block__content {
    padding: 64px;
    padding-top: 24px;
    width: 100%;

    @media all and (max-width: 1600px) {
      padding: 32px;
    }

    @media all and (max-width: 1200px) {
      padding: 24px;
    }

    @media all and (max-width: 800px) {
      padding: 16px;
    }

    ul {
      color: white;
      font-size: 2.4rem;
      letter-spacing: 0.05em;
      line-height: 1.65;
      padding-left: 64px;
      padding-right: 32px;
      font-weight: 300;

      @media all and (max-width: 1200px) {
        font-size: 2rem;
      }

      @media all and (max-width: 800px) {
        padding-left: 32px;
        font-size: 1.6rem;
      }
    }

    p {
      color: white;
      font-size: 2.4rem;
      letter-spacing: 0.05em;
      line-height: 1.65;
      padding-left: 32px;
      padding-right: 32px;
      font-weight: 300;

      @media all and (max-width: 1200px) {
        font-size: 2rem;
      }

      @media all and (max-width: 800px) {
        padding: 0px;
        font-size: 1.6rem;
      }

      strong {
        font-weight: 600;
      }
    }

    .codesWrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 1200px;
      margin: auto;
      padding-bottom: 24px;
      justify-content: center;

      code {
        padding: 64px 64px 0px 64px;
        display: block;

        @media all and (max-width: 1200px) {
          padding: 32x 32x 0px 32x;
        }

        @media all and (max-width: 800px) {
          padding: 16px 12px 0px 12px;
        }
      }
    }
  }

  .ReactCollapse--collapse {
    transition: height 0.3s;
  }
}