.uslugi-page {
  width: 100%;
  height: auto;
  background: white;
  padding-bottom: 32px;
  ul{
    padding-left: 64px;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0px;
    li{
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
  @media all and (max-width: 800px) {
    padding-bottom: 0px;
  }
}