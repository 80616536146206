.arturGrzyb-page {
  width: 100%;
  height: auto;
  background: white;

  .offerItemsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 32px;

    @media all and (max-width: 1600px) {
      padding: 0px 40px;
    }
    @media all and (max-width: 1200px) {
      padding: 0px 16px;
    }

    .topLinkWrapper {
      width: 25%;

      @media all and (max-width: 1600px) {
        width: 33.3333%;
        line-height: 1.5;
      }

      @media all and (max-width: 1200px) {
        width: 50%;
      }

      @media all and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}