.rules-page {
  height: auto;
  width: 100%;
  background: white;
  padding-top: 128px;
  padding-bottom: 64px;
  max-width: 1000px;
  margin: auto;

  @media all and (max-width: 800px) {
    padding-top: 96px;
    padding-bottom: 0px;
  }

  h3 {
    margin-right: auto;
    padding-bottom: 32px;
    padding-top: 64px;
    font-size: 3.6rem;
    font-weight: 500;

    @media all and (max-width: 1200px) {
      font-size: 2.8rem;
    }

    @media all and (max-width: 800px) {
      font-size: 2.4rem;
      padding-top: 32px;
      padding-bottom: 16px;
    }
  }

  .policy {
    font-size: 2rem;
    line-height: 2;
    width: 100%;
    margin-bottom: 64px;


    @media all and (max-width: 1200px) {
      font-size: 1.8rem;
      line-height: 1.8;
      margin-bottom: 32px;
    }

    @media all and (max-width: 800px) {
      font-size: 1.6rem;
      line-height: 1.6;
    }

    p {
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: 300;
    }

    ol {
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: 300;
    }

    strong {
      font-weight: 600;
    }
  }
}